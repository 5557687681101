import React, { useEffect } from "react";
import Script from "next/script"
import Head from "next/head";
import { RecoilRoot } from "recoil";
import { useRouter } from 'next/router';
import Error from 'next/error';


import "antd/dist/antd.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-multi-carousel/lib/styles.css";
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/css/fontawesome.min.css';

import '../public/assets/slick/slick.css';
import '../public/assets/slick/slick-theme.css';
import '../styles/style.css';
import '../styles/responsive.css';
import '../styles/content-styles.css';
import '../styles/landing.css';
import '../styles/foreignrightsintro.css';
import '../styles/newscarrousel.css';
import '../styles/styles-landing.css';
import '../styles/styles-landing-batman.css';
import '../styles/responsive-landing.css';
import '../styles/responsive-landing-batman.css';
import MaintenancePage from "../components/common/MaintenancePage";

import ConfigManager from "../components/Managers/ConfigManager.jsx";
import UserManager from "../components/Managers/UserManager.jsx";
import CartManager from "../components/Managers/CartManager.jsx";
import FacebookPixel from "../lib/utils/facebook/index.js";


if (typeof window !== "undefined") {
  require("lazysizes/plugins/attrchange/ls.attrchange.js");
  require("lazysizes/plugins/respimg/ls.respimg.js");
  require("lazysizes");
}

const MyApp = ({ Component, pageProps }) => {	
    const router = useRouter();
	
    useEffect(() => {
        router.events.on('routeChangeComplete', handleRouteChange);
        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, [router.events]);

    const handleRouteChange = (url) => {
        window.gtag('config', 'G-KKE4CGDH60', {
        page_path: url,
        });
    };

    if(pageProps.error){
        return <Error {...pageProps.error} />
    }
    if(process.env.NEXT_PUBLIC_MAINTENANCE_MODE === "1"){
        return <MaintenancePage />
    }

    return (
        <>
            <Head>
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"/>            
                <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />                
                <script async src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>                        
                <link rel="icon" type="image/x-icon" href="https://content.eccediciones.com/web-assets/ecc-favicon.ico"></link>
                <meta name="facebook-domain-verification" content="ndwnqas6slw4jq4dze7mppj654eo37" />
                <script
                    async
                    src="https://www.googletagmanager.com/gtag/js?id=G-KKE4CGDH60"
                />
                <script
                    dangerouslySetInnerHTML={{
                    __html: `
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', 'G-KKE4CGDH60', { page_path: window.location.pathname });
                    `,
                    }}
                />                                                       
            </Head>
            <RecoilRoot>            
                <Script id="google-tag-manager" strategy="afterInteractive">
                {`
                    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','GTM-N8P4MT5');
                `}
                </Script>
                <script async src="https://consent.cookiefirst.com/sites/eccediciones.com-4e54e62c-383a-4acc-ad0b-5126e4219f3d/consent.js"></script>
				<Script id="metricool" strategy="afterInteractive">
                {`
                    function loadScript(a){var b=document.getElementsByTagName("head")[0],c=document.createElement("script");c.type="text/javascript",c.src="https://tracker.metricool.com/resources/be.js",c.onreadystatechange=a,c.onload=a,b.appendChild(c)}loadScript(function(){beTracker.t({hash:"cb4d6cf929197e24796ee09138db8686"})});
                `}
                </Script>
				<ConfigManager></ConfigManager>
				<UserManager></UserManager>
        		<CartManager></CartManager>
        		<FacebookPixel></FacebookPixel>

                <Component {...pageProps} />


				
                <script async src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.16.0/umd/popper.min.js"></script>
                <script async src="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script>
                <script async src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"></script>
            </RecoilRoot>
        </>

)};

export default MyApp;
