import getRootUrl from './getRootUrl';
import { setCookies, getCookies, removeCookies } from 'cookies-next';

export default async function sendRequest(path, body, ctx = {}) { 	
    let authToken = getCookies('eccauthToken')?.eccauthToken ? getCookies('eccauthToken')?.eccauthToken : '';
    let shopId= "1"
    let language = "es"

	if(ctx?.req?.cookies?.eccStoreId){		
		shopId = ctx.req.cookies?.eccStoreId;
	}
	if(ctx?.req?.cookies?.eccauthToken){
		authToken = ctx?.req?.cookies?.eccauthToken;
	}
	
	if(ctx?.xShopId){
		shopId = ctx?.xShopId;
	}	    
    
    if (ctx?.resolvedUrl){
        if(ctx?.resolvedUrl.includes("foreign-rights")) {
            shopId =  '8' ;
            language = 'en';
        }
    }
        
    const headers = Object.assign({}, ctx?.headers || {}, {
        'Content-type': 'application/json; charset=UTF-8',
        'Authorization': 'Bearer ' + authToken,
        'X-ShopId' : shopId,
        'X-Lang': language
    });
	
    const response = await fetch(
        `${getRootUrl()}${path}`,
        Object.assign({ method: 'POST', credentials: 'same-origin'}, body, { headers }),
    );
    
    
    if (!response.ok) {
        console.log(path)
        if (response.status === 401 || response.status === 110) {
            removeCookies('ecc_auth_email');
            removeCookies('eccauthToken');
			
			window.location.href='/auth/login';
			
        }
        
        /*Here we should use sentry!!! */
        
        let message = `An error has occured: ${response.status}`;                          
		message += ' ' + path
        
        console.error('error', message)
        throw new Error();
    }else{
        const resolved = await response.json();
        
        if (resolved?.responseCode === 401){
            removeCookies('ecc_auth_email');
            removeCookies('eccauthToken');
            return;
        }
        return resolved
    }        
}